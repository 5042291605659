import { useContext } from "react";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/it";
import { SettingsContext } from "../App";
import Status from "./Status";
import ArrowLeftIcon from "./icons/ArrowLeftIcon";
import ArrowRightIcon from "./icons/ArrowRightIcon";
import Timer from "./Timer";
import LockIcon from "./icons/LockIcon";
import UserIcon from "./icons/UserIcon";
import { config } from "@fortawesome/fontawesome-svg-core";
import InfoIcon from "./icons/InfoIcon";

function Order({ order, setCustomAlert, advanceStatus, backStatus }) {
	const { settings, setOrder } = useContext(SettingsContext);

	async function handlePress(order) {
		console.log("handlePress");
		if (settings.disable_confirm == 0) {
			setCustomAlert({
				title: "Sei sicuro?",
				visible: true,
				onConfirm: () => {
					advanceStatus(order);
				},
				onCancel: () => {},
			});
		} else {
			advanceStatus(order);
		}
	}

	async function handleBack(order) {
		console.log("handleBack");
		setCustomAlert({
			title: "Sei sicuro?",
			visible: true,
			onConfirm: () => {
				backStatus(order);
			},
			onCancel: () => {},
		});
	}

	function getLabel() {
		if (order.table_id) return order.table.name;
		else if (order.delivery_type == "delivery") return "Delivery";
		else if (order.delivery_type == "takeaway") return "Takeaway";
	}

	const getTitle = (title) => {
		if (settings.replace_sell_title == 1 && title) return title.replace("endita ", "");
		return title;
	};

	return (
		<div className="order">
			<div className="order-header">
				<div className="order-date-time">
					<div className="time-insert">
						Inserito alle
						<Moment format={"HH:mm"} locale="it">
							{order.date_insert}
						</Moment>
					</div>

					{order.delivery_type != "shop" && (
						<>
							<div className="icon-arrow">
								<ArrowRightIcon></ArrowRightIcon>
							</div>
							<div className="time-prep">
								Per le
								<Moment format={"HH:mm"} locale="it">
									{order.delivery_date}
								</Moment>
							</div>
						</>
					)}
				</div>
				{settings.hide_order_n == "0" && (
					<div className="header-order-info">
						{(!settings.show_single_id || settings.show_single_id == 0) && (
							<b>
								{order.number} ({order.id}){" "}
								{settings.show_sell_title && getTitle(order.scloby_sell_name)}
							</b>
						)}
						{settings.show_single_id == 1 && (
							<b>
								{order.scloby_sell_name
									? getTitle(order.scloby_sell_name)
									: order.number + " (" + order.id + ")"}
							</b>
						)}
					</div>
				)}

				<div className="timer-flow">
					<div className="order-type">{getLabel()}</div>

					<Timer order={order} />
				</div>
				{settings.show_customer == 1 && order.name && (
					<div className="customer-name">
						<UserIcon className="icon me-1" />
						{order.name + " " + order.surname}
					</div>
				)}
			</div>
			<div className="content">
				<div onClick={() => setOrder(order)} className="v-group">
					<div>
						{order.flow && <div className="order-flow">{"Portata " + order.flow}</div>}
					</div>
					{order.date_filled && (
						<div className="small">
							Completato alle{" "}
							<Moment format={"HH:mm"} locale="it">
								{order.date_filled}
							</Moment>
						</div>
					)}
					{order.products.map((product) =>
						product.products.length == 0 ? (
							<div key={product.id}>
								<Product product={product} />
							</div>
						) : (
							<div key={product.id}>
								<div className="product-group">
									<div
										className="product-group-title"
										style={{
											fontSize:
												settings.font_size_product != ""
													? settings.font_size_product + "px"
													: "16px",
										}}
									>
										{product.name}
									</div>
									<div className="v-group-sm">
										{product.products.map((subproduct, i) => (
											<div key={i}>
												<Product product={subproduct} />
											</div>
										))}
									</div>
								</div>
							</div>
						)
					)}
					{order.notes && (
						<div
							className={"mb-2" + (settings.highlight_notes == 1 ? " highlight" : "")}
							style={{
								fontSize:
									settings.font_size_product != ""
										? settings.font_size_product + "px"
										: "16px",
							}}
						>
							<b>Note cliente:</b>
							<div className="small">{order.notes}</div>
						</div>
					)}
				</div>

				{settings.buttons_type != "hidden" && (
					<div className={"section-button buttons-type-" + settings.buttons_type}>
						<div className="back-button">
							{order.status != "confirmed" && (
								<button
									className="btn btn-secondary btn-block"
									onClick={() => handleBack(order)}
								>
									{settings.buttons_type == "small" ? (
										<ArrowLeftIcon className="icon" />
									) : (
										"Arretra"
									)}
								</button>
							)}
						</div>
						<div className="push-button">
							{order.status != "filled" && (
								<button
									className="btn btn-success btn-block"
									onClick={() => handlePress(order)}
								>
									{settings.buttons_type == "small" ? (
										<ArrowRightIcon className="icon" />
									) : (
										"Avanza"
									)}
								</button>
							)}
						</div>
					</div>
				)}
			</div>
			{settings.enable_flow == "1" && parseInt(order.flow) > parseInt(order.current_flow) && (
				<div className="order-overlay">
					<div>
						<LockIcon />
						Portata <br></br>non attiva
					</div>
				</div>
			)}
		</div>
	);
}

function Product({ product }) {
	const { settings } = useContext(SettingsContext);

	return (
		<>
			<div className="product">
				<div
					className="horizzontal-group"
					style={{
						fontSize:
							settings.font_size_product != ""
								? settings.font_size_product + "px"
								: "16px",
					}}
				>
					<div className="product-number">
						<div
							className="product-tag"
							style={{
								fontSize:
									settings.font_size_product != ""
										? settings.font_size_product - 4 + "px"
										: "12px",
							}}
						>
							{product.qty}
						</div>{" "}
					</div>
					{product.name}
				</div>
				<div>
					<Status status={product.status} />
				</div>
			</div>
			<div className="variants">
				{product.notes && (
					<div className="variant ingredients">
						<b>Info prodotto:</b>
						<div
							style={{
								fontSize:
									settings.font_size_variant != ""
										? settings.font_size_variant + "px"
										: "12px",
							}}
							dangerouslySetInnerHTML={{ __html: product.notes }}
						/>
					</div>
				)}
			</div>
			{product.variants.length > 0 && (
				<div className="variants">
					{product.variants.map((variants_category, i) => (
						<div
							key={i}
							className="variant"
							style={{
								fontSize:
									settings.font_size_variant != ""
										? settings.font_size_variant + "px"
										: "12px",
							}}
						>
							{settings.show_variants_categories == 1 && (
								<div className="variant-title">{variants_category.name}</div>
							)}
							{variants_category.variants.map((variant, i) =>
								settings.inline_variants == 1 ? (
									<span key={i}>
										{variant.qty}x {variant.name}
										{i < variants_category.variants.length - 1 && ", "}
									</span>
								) : (
									<div key={i}>
										{variant.qty}x {variant.name}
									</div>
								)
							)}
						</div>
					))}
				</div>
			)}
			{settings.show_ingredients && product.ingredients?.length > 0 && (
				<div className="variants">
					<div
						className="variant"
						style={{
							fontSize:
								settings.font_size_variant != ""
									? settings.font_size_variant + "px"
									: "12px",
						}}
					>
						<div className="variant-title">Ingredienti prodotto</div>
						{product.ingredients &&
							product.ingredients.map((ingredient, i) =>
								settings.inline_variants == 1 ? (
									<span key={i}>
										{ingredient.name}
										{i < product.ingredients.length - 1 && ", "}
									</span>
								) : (
									<div key={i}>{ingredient.name}</div>
								)
							)}
					</div>
				</div>
			)}
		</>
	);
}

export default Order;
