import React, { useState, useEffect, useRef } from "react";
import { socket } from "./socket";
import Api from "./components/api/api";
import Login from "./components/login";
import Main from "./components/main";
import api from "./components/api/api";
import Loading from "./components/loading";
import Splash from "./components/Splash";
import Confirm from "./components/Confirm";
import "bootstrap/dist/css/bootstrap.css";
import "./css/App.css";

export const UserContext = React.createContext();
export const ShopContext = React.createContext();
export const SettingsContext = React.createContext();

function App() {
	const [splash, setSplash] = useState(true);
	const [loading, setLoading] = useState(false);
	const [confirmData, setConfirmData] = useState(false);
	const [user, setUser] = useState(false);
	const [shop, setShop] = useState(false);
	const [mandantId, setMandantId] = useState(false);
	const [settings, setSettings] = useState({
		template: {
			id: 2,
			name: "Attesa / Preparazione / Pronto",
			columns: [
				{ status: "confirmed", label: "In attesa" },
				{ status: "preparation", label: "In preparazione" },
				{ status: "ready", label: "Pronto" },
			],
			orders_order: "delivery_date",
			formats: 3,
		},
		format: "3,6,3",
		enable_flow: "0",
		disable_confirm: "0",
		filled_limit: "",
		inline_variants: "0",
		show_variants_categories: "0",
		font_size_product: "",
		font_size_variant: "",
		buttons_type: "",
		warning_time: 0,
		alert_time: 10,
		preorder_delivery: 30,
		origins: [],
		rooms: [],
		self_mode: "",
		hide_order_n: "0",
		show_active_flow_on_top: "0",
		show_sell_title: "0",
		replace_sell_title: "0",
		show_single_id: "0",
		show_customer: "0",
		show_ingredients: "0",
		highlight_notes: "0",
	});
	const [productionCenters, setProductionCenters] = useState(false);
	const [productionCenter, setProductionCenter] = useState(false);
	const [view, setView] = useState("");
	const [order, setOrder] = useState(false);
	const [autofocus, setAutofocus] = useState(
		localStorage.getItem("kitchenmonitor-autofocus")
			? localStorage.getItem("kitchenmonitor-autofocus") == "true"
			: true
	);
	const [orders, setOrders] = useState([]);
	const audio = useRef(null);
	const [settingsVisible, setSettingsVisible] = useState(false);
	const [rooms, setRooms] = useState(false);

	useEffect(() => {
		function onConnect() {
			console.log("Socket connected", socket.id);
			subscribe();
		}

		function onDisconnect() {
			console.log("Socket disconnected");
		}

		function onSubrscribed(data) {
			console.log(data);
		}

		console.log("add socket listener", mandantId, shop.id);
		socket.on("connect", onConnect);
		socket.on("disconnect", onDisconnect);
		socket.on("subscribed", onSubrscribed);
		socket.on("events", onSocketEvents);

		subscribe();

		return () => {
			console.log("remove socket listener", mandantId, shop.id);
			socket.off("connect", onConnect);
			socket.off("disconnect", onDisconnect);
			socket.off("subscribed", onSubrscribed);
			socket.off("events", onSocketEvents);
		};
	}, [mandantId, shop, productionCenter, settings]);

	useEffect(() => subscribe(), [socket.connected]);

	function subscribe() {
		if (mandantId && shop) {
			socket.emit("subscribe", "m" + mandantId + ".s" + shop.id + ".orders.#");
		}
	}

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		if (shop) {
			load_production_centers();
			load_rooms();
		}
	}, [shop]);

	useEffect(() => {
		if (settingsVisible) setAutofocus(false);
	}, [settingsVisible]);

	useEffect(() => {
		console.log("autofocus", autofocus);
		if (autofocus) document.getElementById("qrFormField")?.focus();
		localStorage.setItem("kitchenmonitor-autofocus", autofocus);
	}, [autofocus]);

	async function init() {
		const oauth = await api.oauth();
		setMandantId(oauth.mandant_id);
		await reload_user();
		await reload_shop();
		await reload_production_center();
		await reload_settings();
		setSplash(false);
	}

	async function load_production_centers() {
		const production_centers = await api.post("/production_centers/list/?debug=1", {
			src: [{ name: "shop_id", value: shop.id, compare: "equal" }],
		});
		setProductionCenters(production_centers.rows.length > 0 ? production_centers.rows : false);
	}

	async function load_rooms() {
		const rooms = await api.post("/rooms/list/?debug=1", {
			src: [{ name: "shop_id", value: shop.id, compare: "equal" }],
		});
		setRooms(rooms.rows.length > 0 ? rooms.rows : false);
	}

	async function reload_user() {
		const tuser = localStorage.getItem("user");
		console.log(tuser);
		if (tuser) {
			const userObj = await Api.post("/users/get/" + tuser + "/");
			console.log(userObj);
			setUser(userObj.data);
		}
	}

	async function reload_shop() {
		const shopId = localStorage.getItem("shop_id");
		console.log("shopId", shopId);
		if (shopId) {
			const response = await Api.post("/shops/get/" + shopId + "/");
			console.log(response);
			setShop(response.data);
		}
	}

	async function reload_production_center() {
		const id = localStorage.getItem("production_center_id");
		console.log(id);
		if (id) {
			const item = await Api.post("/production_centers/get/" + id + "/");
			console.log(item);
			setProductionCenter(item.data);
		}
	}

	async function reload_settings() {
		const tsettings = localStorage.getItem("settings");
		if (tsettings) {
			setSettings(JSON.parse(tsettings));
		}
	}

	async function updateOrders(callback) {
		console.log("load_orders");
		var request = {
			shop_id: shop.id,
			production_center_id: productionCenter.id,
			order: settings.orders_order,
			enable_flow: settings.enable_flow,
			origins: settings.origins,
			rooms: settings.rooms,
			ingredients: settings.show_ingredients,
		};
		var response = await Api.post("/kitchenmonitor/orders/", request);
		if (response.success == 1) {
			setOrders(response);
			if (typeof callback == "function") callback();
		} else {
			alert(response.error);
		}
	}

	function onSocketEvents(message) {
		console.log("new socket event: ", message);
		if (message.table == "orders") updateOrders();
		if (message.table == "settings") updateOrders();
	}

	return (
		<>
			<audio ref={audio}>
				<source src="https://backend.dev.yellgo.cloud/sounds/coin.wav" type="audio/mpeg" />
				<p>Your browser does not support the audio element.</p>
			</audio>
			{splash ? (
				<Splash />
			) : (
				<UserContext.Provider value={{ user, setUser }}>
					<ShopContext.Provider
						value={{
							shop,
							setShop,
							productionCenters,
							productionCenter,
							setProductionCenter,
						}}
					>
						<SettingsContext.Provider
							value={{
								settings,
								setSettings,
								view,
								setView,
								loading,
								setLoading,
								order,
								setOrder,
								setConfirmData,
								autofocus,
								setAutofocus,
								orders,
								setOrders,
								updateOrders,
								audio,
								settingsVisible,
								setSettingsVisible,
								rooms,
							}}
						>
							{user && shop ? <Main /> : <Login />}
							<Confirm data={confirmData} setData={setConfirmData} />
							{loading && <Loading />}
						</SettingsContext.Provider>
					</ShopContext.Provider>
				</UserContext.Provider>
			)}
		</>
	);
}

export default App;
