import { useContext, useEffect, useState } from "react";
import Order from "./order";
import { SettingsContext } from "../App";

function Column({ index, orders, status, label, setCustomAlert, advanceStatus, backStatus }) {
	const { settings, audio } = useContext(SettingsContext);
	const [filteredOrders, setFilteredOrders] = useState([]);
	const [counter, setCounter] = useState(0);
	const [visible, setVisible] = useState(true);

	useEffect(() => {
		if (orders) {
			filter_orders();
		}
	}, [orders]);

	useEffect(() => {
		setCounter(filteredOrders.length);
		const prevCount = localStorage.getItem("count-" + status);
		if (prevCount !== null && status == "confirmed") {
			console.log(
				parseInt(prevCount),
				filteredOrders.length,
				parseInt(prevCount) != filteredOrders.length
			);
			if (parseInt(prevCount) != filteredOrders.length) {
				console.log("Alert!", status);
				audio.current.play().catch((err) => {
					console.log("Errore", err);
					setTimeout(function () {}, 500);
				});
			}
		}
		localStorage.setItem("count-" + status, filteredOrders.length);
	}, [filteredOrders]);

	useEffect(() => {
		console.log("counter", status, "set to", counter);
	}, [counter]);

	const filter_orders = function () {
		var t_filtered_orders = [];
		for (var order of orders) {
			order.flow_active = parseInt(order.flow) > parseInt(order.current_flow);
			if (order.status == status) t_filtered_orders.push(order);
		}

		if (status === "filled") {
			if (settings.filled_limit != "") t_filtered_orders.splice(settings.filled_limit);
		}

		if (settings.enable_flow == "1" && settings.show_active_flow_on_top == "1") {
			t_filtered_orders.sort(function (a, b) {
				return a.flow_active - b.flow_active;
			});
		}

		setFilteredOrders(t_filtered_orders);
	};

	return (
		<>
			<button
				className="title"
				onClick={() => {
					if (status != "confirmed") setVisible(visible ? false : true);
				}}
			>
				<span className="tag">{counter}</span>
				{visible && <h3>{label}</h3>}
			</button>

			{visible && (
				<div className={"orders-grid" + (index == 0 ? " size-" + settings.format : "")}>
					{filteredOrders.map((order, i) => (
						<Order
							order={order}
							key={i}
							setCustomAlert={setCustomAlert}
							advanceStatus={advanceStatus}
							backStatus={backStatus}
						/>
					))}
				</div>
			)}
		</>
	);
}

export default Column;
