import { useContext, useState } from "react";
import { SettingsContext } from "../App";

function Settings({ visible, setVisible, settings, setSettings }) {
	const { rooms } = useContext(SettingsContext);
	const [settingsPage, setSettingsPage] = useState("orders");

	const data = [
		{
			id: 1,
			name: "Attesa / Preparazione",
			columns: [
				{ status: "confirmed", label: "In attesa" },
				{ status: "preparation", label: "In preparazione" },
			],
			formats: 2,
			default_format: "6,6",
		},
		{
			id: 2,
			name: "Attesa / Preparazione / Pronto",
			columns: [
				{ status: "confirmed", label: "In attesa" },
				{ status: "preparation", label: "In preparazione" },
				{ status: "ready", label: "Pronto" },
			],
			formats: 3,
			default_format: "4,4,4",
		},
		{
			id: 3,
			name: "Attesa / Pronto / Completati",
			columns: [
				{ status: "confirmed", label: "In attesa" },
				{ status: "ready", label: "Pronto" },
				{ status: "filled", label: "Completati" },
			],
			formats: 3,
			default_format: "4,4,4",
		},
		{
			id: 4,
			name: "Attesa / Preparazione / Pronto / Completati",
			columns: [
				{ status: "confirmed", label: "In attesa" },
				{ status: "preparation", label: "In preparazione" },
				{ status: "ready", label: "Pronto" },
				{ status: "filled", label: "Completati" },
			],
			formats: 4,
			default_format: "3,3,3,3",
		},
		{
			id: 5,
			name: "Attesa / Completati",
			columns: [
				{ status: "confirmed", label: "In attesa" },
				{ status: "filled", label: "Completati" },
			],
			formats: 2,
			default_format: "6,6",
		},
		{
			id: 6,
			name: "Attesa / Pronto",
			columns: [
				{ status: "confirmed", label: "In attesa" },
				{ status: "ready", label: "Pronto" },
			],
			formats: 2,
			default_format: "6,6",
		},
		{
			id: 7,
			name: "Preparazione / Completati",
			columns: [
				{ status: "preparation", label: "In preparazione" },
				{ status: "filled", label: "Completati" },
			],
			formats: 2,
			default_format: "6,6",
		},
		{
			id: 8,
			name: "Pronto / Completati",
			columns: [
				{ status: "ready", label: "Pronto" },
				{ status: "filled", label: "Completati" },
			],
			formats: 2,
			default_format: "6,6",
		},
	];

	const origins = [
		{ id: "website", name: "Website" },
		{ id: "selfordering", name: "Selforder" },
		{ id: "mono", name: "Salta fila" },
		{ id: "app", name: "App" },
		{ id: "pos", name: "Pos" },
		{ id: "scloby", name: "Tilby" },
	];

	function onChange(name, value) {
		console.log("save settings", name, value);
		var t_settings = { ...settings };
		if (name == "template") {
			var value = data.filter(function (itm) {
				return itm.id == value;
			});
			value = value[0];
		}
		t_settings[name] = value;
		localStorage.setItem("settings", JSON.stringify(t_settings));
		setSettings(t_settings);
	}

	function onCancel() {
		setVisible(false);
	}

	function saveOrigins() {
		let res = [];
		for (const origin of origins) {
			const el = document.getElementById(origin.id);
			console.log(el.checked);
			if (el.checked) res.push(origin.id);
		}
		var t_settings = { ...settings };
		t_settings["origins"] = res;
		localStorage.setItem("settings", JSON.stringify(t_settings));
		setSettings(t_settings);
	}

	function saveCheckbox(arr, name) {
		let res = [];
		for (const arrEl of arr) {
			console.log(name + "-" + arrEl.id);
			const el = document.getElementById(name + "-" + arrEl.id);
			console.log(el);
			if (el) {
				console.log(el.checked);
				if (el.checked) res.push(arrEl.id);
			}
		}
		var t_settings = { ...settings };
		t_settings[name] = res;
		localStorage.setItem("settings", JSON.stringify(t_settings));
		setSettings(t_settings);
	}

	return (
		<>
			{visible && (
				<div className="settings">
					<div className="content">
						<div className="settings-header">
							<h4 className="mb-0">Impostazioni</h4>
							<button onClick={onCancel}>&times;</button>
						</div>
						<div className="settings-menu">
							<button
								className={settingsPage == "orders" ? "active" : ""}
								onClick={() => setSettingsPage("orders")}
							>
								Lista ordini
							</button>
							<button
								className={settingsPage == "layout" ? "active" : ""}
								onClick={() => setSettingsPage("layout")}
							>
								Visualizzazione
							</button>
						</div>

						<div className="scroll">
							{settingsPage == "orders" && (
								<>
									<div className="row">
										<div className="col-12">
											<div className="mb-3">
												<label className="form-label">
													Ordinamento lista ordini:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("orders_order", e.target.value)
													}
													value={settings.orders_order}
												>
													<option value="delivery_date">
														Data Ritiro/Consegna
													</option>
													<option value="date_confirmed">
														Data Conferma
													</option>
												</select>
											</div>
										</div>
										<div className="col-12">
											<div className="mb-3">
												<label className="form-label">
													Filtro origine:
												</label>
												<div className="settings-check-line">
													{origins.map((el, i) => (
														<div key={i} class="form-check">
															<input
																id={"origins-" + el.id}
																name={el.id}
																type="checkbox"
																class="form-check-input"
																onChange={() =>
																	saveCheckbox(origins, "origins")
																}
																checked={settings.origins?.includes(
																	el.id
																)}
															/>
															<label
																class="form-check-label"
																htmlFor={"origins-" + el.id}
															>
																{el.name}
															</label>
														</div>
													))}
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="mb-3">
												<label className="form-label">Filtro sala:</label>
												<div className="settings-check-line">
													{rooms &&
														rooms.map((el, i) => (
															<div key={i} class="form-check">
																<input
																	id={"rooms-" + el.id}
																	name={el.id}
																	type="checkbox"
																	class="form-check-input"
																	onChange={() =>
																		saveCheckbox(rooms, "rooms")
																	}
																	checked={settings.rooms?.includes(
																		el.id
																	)}
																/>
																<label
																	class="form-check-label"
																	htmlFor={"rooms-" + el.id}
																>
																	{el.name}
																</label>
															</div>
														))}
												</div>
											</div>
										</div>
									</div>
								</>
							)}

							{settingsPage == "layout" && (
								<>
									<div className="row">
										<div className="col-12">
											<div className="mb-3">
												<label htmlFor="email" className="form-label">
													Template:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("template", e.target.value)
													}
													value={settings.template.id}
												>
													{data.map((el, i) => (
														<option key={i} value={el.id}>
															{el.name}
														</option>
													))}
												</select>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label htmlFor="email" className="form-label">
													Visualizza numero ordine:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("hide_order_n", e.target.value)
													}
													value={settings.hide_order_n}
												>
													<option value="0">Si</option>
													<option value="1">No</option>
												</select>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label htmlFor="email" className="form-label">
													Visualizza categorie varianti:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange(
															"show_variants_categories",
															e.target.value
														)
													}
													value={settings.show_variants_categories}
												>
													<option value="0">No</option>
													<option value="1">Si</option>
												</select>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label htmlFor="email" className="form-label">
													Visualizza varianti in linea:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("inline_variants", e.target.value)
													}
													value={settings.inline_variants}
												>
													<option value="0">No</option>
													<option value="1">Si</option>
												</select>
											</div>
										</div>

										<div className="col-6">
											<div className="mb-3">
												<label htmlFor="email" className="form-label">
													Ordini per riga in colonna 1:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("format", e.target.value)
													}
													value={settings.format}
												>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
												</select>
											</div>
										</div>
										<div className="col-6">
											<div className="mb-3">
												<label className="form-label">
													Limite colonna ordini completati:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("filled_limit", e.target.value)
													}
													value={settings.filled_limit}
												>
													<option value="">Nessuno</option>
													<option value="10">10 ordini</option>
													<option value="20">20 ordini</option>
													<option value="50">50 ordini</option>
													<option value="100">100 ordini</option>
												</select>
											</div>
										</div>
										<div className="col-6">
											<div className="mb-3">
												<label htmlFor="email" className="form-label">
													Abilita Portate:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("enable_flow", e.target.value)
													}
													value={settings.enable_flow}
												>
													<option value="0">No</option>
													<option value="1">Si</option>
												</select>
											</div>
										</div>
										<div className="col-6">
											<div className="mb-3">
												<label htmlFor="email" className="form-label">
													Visualizza portate attive in cima:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange(
															"show_active_flow_on_top",
															e.target.value
														)
													}
													value={settings.show_active_flow_on_top}
												>
													<option value="0">No</option>
													<option value="1">Si</option>
												</select>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label htmlFor="email" className="form-label">
													Disabilita popup conferma avanzamento:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("disable_confirm", e.target.value)
													}
													value={settings.disable_confirm}
												>
													<option value="0">No</option>
													<option value="1">Si</option>
												</select>
											</div>
										</div>

										<div className="col-4">
											<div className="mb-3">
												<label className="form-label">
													Dimensione caratteri Prodotto:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange(
															"font_size_product",
															e.target.value
														)
													}
													value={settings.font_size_product}
												>
													<option value="12">12 px</option>
													<option value="14">14 px</option>
													<option value="">16 px</option>
													<option value="18">18 px</option>
													<option value="20">20 px</option>
													<option value="22">22 px</option>
													<option value="24">24 px</option>
												</select>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label className="form-label">
													Dimensione caratteri Variante:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange(
															"font_size_variant",
															e.target.value
														)
													}
													value={settings.font_size_variant}
												>
													<option value="10">10 px</option>
													<option value="">12 px</option>
													<option value="14">14 px</option>
													<option value="16">16 px</option>
													<option value="18">18 px</option>
													<option value="20">20 px</option>
												</select>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label className="form-label">
													Dimensione pulsanti:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("buttons_type", e.target.value)
													}
													value={settings.buttons_type}
												>
													<option value="">Grandi</option>
													<option value="small">Piccoli</option>
													<option value="hidden">Nascosti</option>
												</select>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label className="form-label">
													Minuti notifica ritardo ordine:
												</label>
												<input
													type="number"
													className="form-control"
													onChange={(e) =>
														onChange("warning_time", e.target.value)
													}
													value={settings.warning_time}
												/>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label className="form-label">
													Minuti errore ritardo ordine:
												</label>
												<input
													type="number"
													className="form-control"
													onChange={(e) =>
														onChange("alert_time", e.target.value)
													}
													value={settings.alert_time}
												/>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label className="form-label">
													Preavviso ordini delivery:
												</label>
												<input
													type="number"
													className="form-control"
													onChange={(e) =>
														onChange(
															"preorder_delivery",
															e.target.value
														)
													}
													value={settings.preorder_delivery}
												/>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label className="form-label">
													Visualizza nome vendita Tilby:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("show_sell_title", e.target.value)
													}
													value={settings.show_sell_title}
												>
													<option value="0">No</option>
													<option value="1">Si</option>
												</select>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label className="form-label">
													Abbrevia nome vendita Tilby:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange(
															"replace_sell_title",
															e.target.value
														)
													}
													value={settings.replace_sell_title}
												>
													<option value="0">No</option>
													<option value="1">Si</option>
												</select>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label className="form-label">
													Numero ordine singolo:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("show_single_id", e.target.value)
													}
													value={settings.show_single_id}
												>
													<option value="0">No</option>
													<option value="1">Si</option>
												</select>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label className="form-label">
													Mostra cliente:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("show_customer", e.target.value)
													}
													value={settings.show_customer}
												>
													<option value="0">No</option>
													<option value="1">Si</option>
												</select>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label className="form-label">
													Evidenzia note cliente:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("highlight_notes", e.target.value)
													}
													value={settings.highlight_notes}
												>
													<option value="0">No</option>
													<option value="1">Si</option>
												</select>
											</div>
										</div>
										<div className="col-4">
											<div className="mb-3">
												<label className="form-label">
													Mostra ingredienti:
												</label>
												<select
													className="form-select"
													onChange={(e) =>
														onChange("show_ingredients", e.target.value)
													}
													value={settings.show_ingredients}
												>
													<option value="0">No</option>
													<option value="1">Si</option>
												</select>
											</div>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Settings;
